import React from "react";
import "./Home.css";
import Banner from "../../components/Banner";
import Skills from "../../components/Skills";
import Me from "../../components/Me";
import { FaAnglesDown } from "react-icons/fa6";
import WebDevelopment from "../../components/WebDevelopment";
import ServerManagement from "../../components/ServerManagement";
import NavBar from "../../components/NavBar";
import Reveal from "../../components/Reveal";

function Home() {
  return (
    <div id="Home">
      <Banner />
      <div className="pointDown left">
        <FaAnglesDown />
      </div>
      <div className="pointDown">
        <FaAnglesDown />
      </div>
      <div className="pointDown right">
        <FaAnglesDown />
      </div>
      <div className="sections">
        <Reveal>
          <Me />
        </Reveal>
        <Reveal>
          <Skills />
        </Reveal>
        <Reveal>
          <WebDevelopment />
        </Reveal>
        <Reveal>
          <ServerManagement />
        </Reveal>

        <NavBar />
      </div>
    </div>
  );
}

export default Home;
